<template>
    <div class="page">
        <el-row type="flex" justify="center">
            <el-col :xl="11">
                <div class="left_box">
                    <!-- logo -->
                    <el-image
                        :src="logo"
                        fit="contain"
                        style="width: 430px; height: 34rpx;"
                    ></el-image>
                    <!-- nav -->
                    <div class="login_nav no_select">
                        <div :class="{ active: true }">账号密码登录</div>
                        <!-- <div>微信登录</div> -->
                    </div>
                    <!-- form -->
                    <form class="form">
                        <!-- 账号 -->
                        <div
                            class="account"
                            :class="{ focus: isAccountInputFocus }"
                        >
                            <el-image
                                :src="
                                    isAccountInputFocus
                                        ? require('@/assets/login/user.png')
                                        : require('@/assets/login/user_gray.png')
                                "
                                fit="none"
                                style="width: 22px; height: 22px;"
                            ></el-image>
                            <input
                                class="account_input"
                                type="text"
                                v-model="form.account"
                                placeholder="请输入账号"
                                @focus="isAccountInputFocus = true"
                                @blur="isAccountInputFocus = false"
                            />
                        </div>
                        <!-- 密码 -->
                        <div
                            class="password"
                            :class="{ focus: isPasswordInputFocus }"
                        >
                            <el-image
                                :src="
                                    isPasswordInputFocus
                                        ? require('@/assets/login/lock.png')
                                        : require('@/assets/login/lock_gray.png')
                                "
                                fit="none"
                                style="width: 22px; height: 22px;"
                            ></el-image>
                            <input
                                name="password"
                                class="password_input"
                                :type="isShowPassword ? 'text' : 'password'"
                                v-model="form.password"
                                placeholder="请输入密码"
                                @focus="isPasswordInputFocus = true"
                                @blur="isPasswordInputFocus = false"
                            />
                            <i
                                class="el-icon-view"
                                @click="isShowPassword = !isShowPassword"
                            ></i>
                        </div>
                    </form>

                    <!-- 注册 -->
                    <div class="func_btns">
                        <!-- <div class="register">
                            还没有账户？点击<span
                                @click="$router.push({ name: 'Register' })"
                                >立即注册</span
                            >
                        </div> -->
                        <div
                            class="forget"
                            @click="$router.push({ name: 'ForgetPassword' })"
                        >
                            忘记密码
                        </div>
                    </div>

                    <div
                        class="login_btn no_select"
                        :class="{ visible: !isLogin }"
                        v-loading="isLogin"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        @click="toLogin"
                    >
                        立即登录<el-image
                            :src="require('@/assets/login/enter.png')"
                            fit="none"
                            style="width: 15px; height: 15px;"
                        ></el-image>
                    </div>
                </div>
            </el-col>
            <el-col :xl="13">
                <el-image
                    style="width: 100%; height: 100vh; min-height: 875px;"
                    :src="require('@/assets/login/bg.png')"
                    fit="cover"
                ></el-image>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            isAccountInputFocus: false,
            isPasswordInputFocus: false,
            isShowPassword: false,
            isLogin: false,
            form: {
                account: "",
                password: "",
            },
            warning: ""
        };
    },
    created() {
        // console.log(this.$route.query.owner_id);
        if (this.$route.query.owner_id) {
            this.getOwnerInfo({ owner_id: this.$route.query.owner_id });
        }
    },
    computed: {
        logo() {
            return this.$store.getters["users/owner"].logo;
        },
    },
    methods: {
        ...mapActions("users", ["login", "registerFdd", "getOwnerInfo", "getauthority"]),

        toLogin() {
            console.log(this.form);
            if (!this.form.account) {
                this.$message.error("请输入账号");
                return;
            }

            if (!this.form.account) {
                this.$message.error("请输入密码");
                return;
            }
            this.isLogin = true;

            this.login({
                account: this.form.account,
                password: this.form.password,
                type: 1,
            })
                .then((res) => {
                    this.registerFdd();
                    // 获取数据缓存
                    // debugger;
                    this.getauthority(res.data.data.uid);
                    console.log("登录", res);
                    this.$message.success("登录成功");
                    this.$router.push("/");
                })
                .catch((e) => {
                    console.error(e);
                    this.$message.error({
                        message: e.msg,
                        type: "warning",
                        showClose: true
                    });
                    this.isLogin = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    background: linear-gradient(to bottom, #363855, #151735);
    height: 100%;
    min-width: 680px;
    // overflow: hidden;
}

.left_box {
    padding-top: 80px;
    margin: 0 auto;
    width: max-content;

    .login_nav {
        color: #9c9c9c;
        font-size: 22px;
        display: flex;
        align-items: flex-end;
        margin-top: 190px;

        > div {
            margin-right: 40px;
            padding-bottom: 17px;
            cursor: pointer;
        }

        > div.active {
            position: relative;
            font-size: 28px;
            letter-spacing: 2px;
            color: #ffffff;

            &::after {
                content: "";
                width: 30px;
                height: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #4db9d5;
            }
        }
    }

    .form {
        margin-top: 60px;

        .account,
        .password {
            border: 1px solid #47474d;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding: 25px;
            width: 540px;

            &.focus {
                border: 1px solid #ffffff;
            }

            .account_input,
            .password_input {
                background: none;
                border: 0;
                outline: none;
                background-color: transparent;

                color: #ffffff;
                font-size: 18px;
                caret-color: #4db9d5;

                margin-left: 30px;
                width: 100%;
            }
        }

        .password {
            margin-top: 30px;

            .el-icon-view {
                font-size: 24px;
                color: #393940;
                cursor: pointer;

                &:hover {
                    filter: brightness(2);
                }
            }
        }
    }

    .func_btns {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin-top: 30px;

        .register {
            color: #505051;

            span {
                color: #4db9d5;
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .forget {
            color: #4db9d5;
            cursor: pointer;
        }
    }

    .login_btn {
        width: 100%;
        height: 80px;
        background-color: #4db9d5;
        border-radius: 5px;
        font-size: 20px;
        color: #ffffff;
        line-height: 80px;
        text-align: center;
        margin-top: 60px;

        &.visible:active {
            filter: brightness(1.2);
        }

        .el-image {
            margin-left: 10px;
        }
    }
}
</style>
